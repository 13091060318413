<script>
import simplebar from "simplebar-vue";

export default {
  components: {
    simplebar
  },
  props: {
    applyButtonTitle: {
      type: String,
      required: false,
      default: 'Apply'
    },
    resetButtonNoAction:{
      type: Boolean,
      required: false,
      default: false
    },
    persistent: {
      type: Boolean,
      required: false,
      default: true
    },
    values: {
      type: Object,
      required: true,
    },
    criteria: {
      type: Object,
      required: false
    }
  },
  created() {
     this.filters.push({
      nameAll: 'Select All',
      nameNotAll: 'Reset',
        func(elem) {
          return elem.selected;
        }
    });
    this.p_criteria = this.criteria ? this.criteria : this.defaultCriteria();
    this.load();
  },
  watch: { 
    criteria: function(newVal) { 
       this.p_criteria = newVal ? newVal : this.defaultCriteria();
    }
  },
  data() {
    return {
      isReady: false,
      filters:[],
      p_criteria: this.defaultCriteria()
    };
  },
  computed: {
    isFilterEnabled() {
      if(this.isStatFiltered) return true;
      if(!this.p_criteria.singleIndices) return true;
      if(!this.p_criteria.compositeIndices) return true;
      if((this.p_criteria.portfolioSolution || []).length > 0) return true;
      if(this.p_criteria.assetClass.length > 0) return true;
      if(this.p_criteria.instrumentType.length > 0) return true;
      if(this.p_criteria.indexType.length > 0) return true;
      if(this.p_criteria.rebalanceFrequency.length > 0) return true;
      if(this.p_criteria.market.length > 0) return true;
      if(this.p_criteria.keyword) return true;
      if(this.p_criteria.provider.length > 0) return true;

      return false;
    },
    isStatFiltered() {
      if(this.p_criteria.mtd.from !== null && this.p_criteria.mtd.from !== undefined) return true;
      if(this.p_criteria.mtd.till !== null && this.p_criteria.mtd.till !== undefined) return true;
      
      if(this.p_criteria.ytd.from !== null && this.p_criteria.ytd.from !== undefined) return true;
      if(this.p_criteria.ytd.till !== null && this.p_criteria.ytd.till !== undefined) return true;

      if(this.p_criteria.annualisedReturn.from !== null && this.p_criteria.annualisedReturn.from !== undefined) return true;
      if(this.p_criteria.annualisedReturn.till !== null && this.p_criteria.annualisedReturn.till !== undefined) return true;

      if(this.p_criteria.realisedVolatility.from !== null && this.p_criteria.realisedVolatility.from !== undefined) return true;
      if(this.p_criteria.realisedVolatility.till !== null && this.p_criteria.realisedVolatility.till !== undefined) return true;

      if(this.p_criteria.downsideVolatility.from !== null && this.p_criteria.downsideVolatility.from !== undefined) return true;
      if(this.p_criteria.downsideVolatility.till !== null && this.p_criteria.downsideVolatility.till !== undefined) return true;
      
      if(this.p_criteria.maxDrawdown.from !== null && this.p_criteria.maxDrawdown.from !== undefined) return true;
      if(this.p_criteria.maxDrawdown.till !== null && this.p_criteria.maxDrawdown.till !== undefined) return true;

      if(this.p_criteria.winningMonths.from !== null && this.p_criteria.winningMonths.from !== undefined) return true;
      if(this.p_criteria.winningMonths.till !== null && this.p_criteria.winningMonths.till !== undefined) return true;

      if(this.p_criteria.sharpeRatio.from !== null && this.p_criteria.sharpeRatio.from !== undefined) return true;
      if(this.p_criteria.sharpeRatio.till !== null && this.p_criteria.sharpeRatio.till !== undefined) return true;

      if(this.p_criteria.sortinoRatio.from !== null && this.p_criteria.sortinoRatio.from !== undefined) return true;
      if(this.p_criteria.sortinoRatio.till !== null && this.p_criteria.sortinoRatio.till !== undefined) return true;

      if(this.p_criteria.calmarRatio.from !== null && this.p_criteria.calmarRatio.from !== undefined) return true;
      if(this.p_criteria.calmarRatio.till !== null && this.p_criteria.calmarRatio.till !== undefined) return true;


      return false;
    }
  },
  methods:{
    
    resetCriteria() {
      this.p_criteria = this.defaultCriteria();
      if(!this.resetButtonNoAction) {
        this.applyFilters();
      }
    },
    save() {
      const json =JSON.stringify(this.p_criteria);
     
      localStorage.setItem('indices_filter_criteria', json);
    },
    load() {
      if(this.persistent) {
        let dataStr = localStorage.getItem('indices_filter_criteria');
        if(dataStr) {
          const data = JSON.parse(dataStr);
          // data.provider = this.values.provider.filter(a => data.provider.find(x => x.value === a.value));
          // data.provider.forEach(x => x.select = true);
           if(data.singleIndices === undefined || data.singleIndices === null){
            data.singleIndices = true;
          }
          if(data.compositeIndices === undefined || data.compositeIndices === null){
            data.compositeIndices = true;
          }

          this.p_criteria = data;
         
        
          this.$emit('load', this.p_criteria);
          this.isReady = true;
        }else{
          
          this.$emit('load', this.p_criteria);
          this.isReady = true;
        }
      } else {
        
        this.$emit('load', this.p_criteria);
        this.isReady = true;
      }
    },
    applyFilters () {
      this.save();
      this.$emit('apply', this.p_criteria);
    },
    defaultCriteria() {
      return {
        bookmarksOnly: false,
        keyword: null,
        provider: [],
        market: [],
        rebalanceFrequency: [],
        indexType: [],
        indexSubType: [],
        instrumentType:[],
        assetClass: [],
        mtd:{from:null, till:null},
        ytd:{from:null, till:null},
        annualisedReturn:{from:null, till:null},
        realisedVolatility:{from:null, till:null},
        downsideVolatility: {from:null, till:null},
        maxDrawdown: {from:null, till:null},
        winningMonths: {from:null, till:null},
        sharpeRatio: {from:null, till:null},
        sortinoRatio: {from:null, till:null},
        calmarRatio: {from:null, till:null},
        singleIndices: true,
        compositeIndices: true,
        portfolioSolution:[],
      };
    }
  }
};
</script>

<template>

    <div class="card cardc8"  v-if="isReady">
      <div class="card-content">
        <div class="card-header bg-transparent d-flex justify-content-between">

          <h5>Filters</h5>
          <span >
            <b-form-checkbox v-model="p_criteria.bookmarksOnly" switch class="mr-1 " style="display:inline-block;">
          
            </b-form-checkbox>Bookmarks only
          </span>
        </div>
        <div class="card-body">
         
          <div class="row">
            <div class="col-md-12">
              <b-form-group
                class="mt-2" 
                label-for="type"
              >
                <div class="search-box">
                  <div class="position-relative">
                    <input type="text" class="form-control rounded" v-model="p_criteria.keyword" :placeholder="$t('pages.indices.keyword')"  id="keywords"/>
                    <i class="mdi mdi-magnify search-icon"></i>
                  </div>
                </div>
              </b-form-group>
            </div>
          </div>
          
          <div id="accordion" class="custom-accordion categories-accordion mb-3">

            <div class="categories-group-card">
              <a v-b-toggle.assetClass class="categories-group-list">
                <i class="mdi mdi-hexagon-multiple-outline font-size-16 align-middle mr-2"></i> 
                <span class="mr-2">Asset class</span>
                <small class="text-primary" v-if="p_criteria.assetClass.length === 1">[ Selected 1 item]</small>
                <small class="text-primary" v-if="p_criteria.assetClass.length > 1">[Selected {{p_criteria.assetClass.length}} items]</small>
                <i class="mdi mdi-minus float-right accor-plus-icon"></i>
              </a>

              <b-collapse id="assetClass" data-parent="#accordion" accordion="assetClass">
                <div class="pl-3 pb-2">
                    <b-form-checkbox-group stacked 
                    v-model="p_criteria.assetClass"
                  >
                    <b-form-checkbox :value="i.value" v-for="i in values.assetClass" :key="i.value">{{i.text}}</b-form-checkbox>
                  </b-form-checkbox-group>
                  
                </div>
              </b-collapse>
            </div>

            <div class="categories-group-card">
              <a v-b-toggle.market class="categories-group-list">
                <i class="mdi mdi-earth font-size-16 align-middle mr-2"></i> 
                  <span class="mr-2">Region</span>
                  <small class="text-primary" v-if="p_criteria.market.length === 1">[ Selected 1 item]</small>
                  <small class="text-primary" v-if="p_criteria.market.length > 1">[Selected {{p_criteria.market.length}} items]</small>
                <i class="mdi mdi-minus float-right accor-plus-icon"></i>
              </a>

              <b-collapse id="market" data-parent="#accordion" accordion="market">
                <div class="pl-3 pb-2">
                  <b-form-checkbox-group stacked 
                    v-model="p_criteria.market"
                  >
                    <b-form-checkbox :value="i.value" v-for="i in values.market" :key="i.value">{{i.text}}</b-form-checkbox>
                  </b-form-checkbox-group>
                </div>
              </b-collapse>
            </div>

            <div class="categories-group-card">
              <a v-b-toggle.indexType class="categories-group-list">
                <i class="mdi mdi-format-list-bulleted-type font-size-16 align-middle mr-2"></i> 
                <span class="mr-2">Index type</span>
                <small class="text-primary" v-if="p_criteria.indexType.length === 1">[ Selected 1 item]</small>
                <small class="text-primary" v-if="p_criteria.indexType.length > 1">[Selected {{p_criteria.indexType.length}} items]</small>
                <i class="mdi mdi-minus float-right accor-plus-icon"></i>
              </a>

              <b-collapse id="indexType" data-parent="#accordion" accordion="indexType">
                <div class="pl-3 pb-2">
                  <b-form-checkbox-group stacked 
                    v-model="p_criteria.indexType"
                  >
                    <template  v-for="i in values.indexType">
                      <b-form-checkbox :value="i.value" :key="i.value">{{i.text}}</b-form-checkbox>
                      <div class="ml-4 small " :key="i.value + 'a'" v-if="(values.indexSubType[i.value] || []).length > 0 && p_criteria.indexType.find(a => a === i.value)">
                        <b-form-checkbox-group stacked 
                            v-model="p_criteria.indexSubType"
                          >
                            <b-form-checkbox :value="s.value" v-for="s in values.indexSubType[i.value]" :key="s.value">{{s.text}}</b-form-checkbox>
                          </b-form-checkbox-group>
                      </div>
                    </template>
                  </b-form-checkbox-group>
                </div>
              </b-collapse>
            </div>

            <div class="categories-group-card">
              <a v-b-toggle.instrumentType class="categories-group-list">
                <i class="mdi mdi-hammer-wrench font-size-16 align-middle mr-2"></i> 
                <span class="mr-2">Instrument type</span>
                <small class="text-primary" v-if="p_criteria.instrumentType.length === 1">[ Selected 1 item]</small>
                <small class="text-primary" v-if="p_criteria.instrumentType.length > 1">[Selected {{p_criteria.instrumentType.length}} items]</small>
                <i class="mdi mdi-minus float-right accor-plus-icon"></i>
              </a>

              <b-collapse id="instrumentType" data-parent="#accordion" accordion="instrumentType">
                <div class="pl-3 pb-2">
                    <b-form-checkbox-group stacked 
                    v-model="p_criteria.instrumentType"
                  >
                    <b-form-checkbox :value="i.value" v-for="i in values.instrumentType" :key="i.value">{{i.text}}</b-form-checkbox>
                  </b-form-checkbox-group>
                </div>
              </b-collapse>
            </div>

            <div class="categories-group-card">
              <a v-b-toggle.provider class="categories-group-list">
                <i class="mdi mdi-bank-outline font-size-16 align-middle mr-2"></i> 
                  <span class="mr-2">Provider</span>
                  <small class="text-primary" v-if="p_criteria.provider.length === 1">[ Selected 1 provider]</small>
                  <small class="text-primary" v-if="p_criteria.provider.length > 1">[Selected {{p_criteria.provider.length}} providers]</small>
                <i class="mdi mdi-minus float-right accor-plus-icon"></i>
              </a>

              <b-collapse id="provider" data-parent="#accordion" accordion="provider">
                <div class="pl-3 pb-2">
                  <simplebar style="max-height:300px">
                    <b-form-checkbox-group stacked 
                      v-model="p_criteria.provider"
                    >
                      <b-form-checkbox :value="i.value" v-for="i in values.provider" :key="i.value">{{i.text}}</b-form-checkbox>
                    </b-form-checkbox-group>
                  </simplebar>
                  
                </div>
              </b-collapse>
            </div>

            <div class="categories-group-card">
              <a v-b-toggle.statistics class="categories-group-list">
                <i class="mdi mdi-filter-variant font-size-16 align-middle mr-2"></i> 
                <span class="mr-2">{{$t('tables.indices.statistics')}}</span>
                <small class="text-primary" v-if="isStatFiltered">[Filter enabled]</small>
                <i class="mdi mdi-minus float-right accor-plus-icon"></i>
              </a>

              <b-collapse id="statistics" data-parent="#accordion" accordion="statistics" style="position:relative;">
                  <div class="pl-3 pb-2 pr-3">
                  <b-form-group
                    class="mt-2"
                    :label="$t('tables.indices.columns.mtd')"
                    label-for="type"
                  >
                    <div class="d-flex flex-row">
                      <div class="flex-fill pr-1">
                        <input class="form-control" type="text" v-model.number="p_criteria.mtd.from"/>
                      </div>
                      <div class="flex-fill pl-1">
                        <input class="form-control" type="text" v-model.number="p_criteria.mtd.till"/>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group
                    class="mt-2"
                    :label="$t('tables.indices.columns.ytd')"
                    label-for="type"
                  >
                    <div class="d-flex flex-row">
                      <div class="flex-fill pr-1">
                        <input class="form-control" type="text" v-model.number="p_criteria.ytd.from"/>
                      </div>
                      <div class="flex-fill pl-1">
                        <input class="form-control" type="text" v-model.number="p_criteria.ytd.till"/>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group
                    class="mt-2"
                    :label="$t('tables.indices.columns.annualisedReturn')"
                    label-for="type"
                  >
                    <div class="d-flex flex-row">
                      <div class="flex-fill pr-1">
                        <input class="form-control" type="text" v-model.number="p_criteria.annualisedReturn.from"/>
                      </div>
                      <div class="flex-fill pl-1">
                        <input class="form-control" type="text" v-model.number="p_criteria.annualisedReturn.till"/>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group
                    class="mt-2"
                    :label="$t('tables.indices.columns.realisedVolatility')"
                    label-for="type"
                  >
                    <div class="d-flex flex-row">
                      <div class="flex-fill pr-1">
                        <input class="form-control" type="text" v-model.number="p_criteria.realisedVolatility.from"/>
                      </div>
                      <div class="flex-fill pl-1">
                        <input class="form-control" type="text" v-model.number="p_criteria.realisedVolatility.till"/>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group
                    class="mt-2"
                    :label="$t('tables.indices.columns.downsideVolatility')"
                    label-for="type"
                  >
                    <div class="d-flex flex-row">
                      <div class="flex-fill pr-1">
                        <input class="form-control" type="text" v-model.number="p_criteria.downsideVolatility.from"/>
                      </div>
                      <div class="flex-fill pl-1">
                        <input class="form-control" type="text" v-model.number="p_criteria.downsideVolatility.till"/>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group
                    class="mt-2"
                    :label="$t('tables.indices.columns.maxDrawdown')"
                    label-for="type"
                  >
                    <div class="d-flex flex-row">
                      <div class="flex-fill pr-1">
                        <input class="form-control" type="text" v-model.number="p_criteria.maxDrawdown.from"/>
                      </div>
                      <div class="flex-fill pl-1">
                        <input class="form-control" type="text" v-model.number="p_criteria.maxDrawdown.till"/>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group
                    class="mt-2"
                    :label="$t('tables.indices.columns.winningMonths')"
                    label-for="type"
                  >
                    <div class="d-flex flex-row">
                      <div class="flex-fill pr-1">
                        <input class="form-control" type="text" v-model.number="p_criteria.winningMonths.from"/>
                      </div>
                      <div class="flex-fill pl-1">
                        <input class="form-control" type="text" v-model.number="p_criteria.winningMonths.till"/>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group
                    class="mt-2"
                    :label="$t('tables.indices.columns.sharpeRatio')"
                    label-for="type"
                  >
                    <div class="d-flex flex-row">
                      <div class="flex-fill pr-1">
                        <input class="form-control" type="text" v-model.number="p_criteria.sharpeRatio.from"/>
                      </div>
                      <div class="flex-fill pl-1">
                        <input class="form-control" type="text" v-model.number="p_criteria.sharpeRatio.till"/>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group
                    class="mt-2"
                    :label="$t('tables.indices.columns.sortinoRatio')"
                    label-for="type"
                  >
                    <div class="d-flex flex-row">
                      <div class="flex-fill pr-1">
                        <input class="form-control" type="text" v-model.number="p_criteria.sortinoRatio.from"/>
                      </div>
                      <div class="flex-fill pl-1">
                        <input class="form-control" type="text" v-model.number="p_criteria.sortinoRatio.till"/>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group
                    class="mt-2"
                    :label="$t('tables.indices.columns.calmarRatio')"
                    label-for="type"
                  >
                    <div class="d-flex flex-row">
                      <div class="flex-fill pr-1">
                        <input class="form-control" type="text" v-model.number="p_criteria.calmarRatio.from"/>
                      </div>
                      <div class="flex-fill pl-1">
                        <input class="form-control" type="text" v-model.number="p_criteria.calmarRatio.till"/>
                      </div>
                    </div>
                  </b-form-group>
                </div>
              </b-collapse>
            </div>
            
            <div class="categories-group-card">
              <a v-b-toggle.rebalanceFrequency class="categories-group-list">
                <i class="mdi mdi-scale-balance font-size-16 align-middle mr-2"></i> 
                <span class="mr-2">Rebalance frequency</span>
                <small class="text-primary" v-if="p_criteria.rebalanceFrequency.length === 1">[ Selected 1 item]</small>
                <small class="text-primary" v-if="p_criteria.rebalanceFrequency.length > 1">[Selected {{p_criteria.rebalanceFrequency.length}} items]</small>
                <i class="mdi mdi-minus float-right accor-plus-icon"></i>
              </a>

              <b-collapse id="rebalanceFrequency" data-parent="#accordion" accordion="rebalanceFrequency">
                <div class="pl-3 pb-2">
                    <b-form-checkbox-group stacked 
                    v-model="p_criteria.rebalanceFrequency"
                  >
                    <b-form-checkbox :value="i.value" v-for="i in values.rebalanceFrequency" :key="i.value">{{i.text}}</b-form-checkbox>
                  </b-form-checkbox-group>
                </div>
              </b-collapse>
            </div>

            <div class="categories-group-card">
              <a v-b-toggle.Optimization class="categories-group-list">
                <i class="fas fa-tachometer-alt font-size-16 align-middle mr-2"></i> 

                <span class="mr-2">Portfolio solution</span>
                <small class="text-primary" v-if="p_criteria.portfolioSolution && p_criteria.portfolioSolution.length === 1">[ Selected 1 item]</small>
                <small class="text-primary" v-if="p_criteria.portfolioSolution && p_criteria.portfolioSolution.length > 1">[Selected {{p_criteria.portfolioSolution.length}} items]</small>

                <i class="mdi mdi-minus float-right accor-plus-icon"></i>
              </a>

              <b-collapse id="Optimization" data-parent="#accordion" accordion="Optimization">
                <div class="pl-3 pb-2">
                  <b-form-checkbox-group stacked 
                    v-model="p_criteria.portfolioSolution"
                  >
                    <b-form-checkbox :value="i.value" v-for="i in values.portfolioSolution" :key="i.value">{{i.text}}</b-form-checkbox>
                  </b-form-checkbox-group>
                </div>
              </b-collapse>
            </div>

            <div class="categories-group-card">
              <a v-b-toggle.Miscellaneous class="categories-group-list">
                <i class="fas fa-peace font-size-16 align-middle mr-2"></i> 
                  <span class="mr-2">Single or Composite </span>
                <small class="text-primary" v-if="p_criteria.singleIndices !== p_criteria.compositeIndices"><br/>[Filter enabled]</small>
                
                <i class="mdi mdi-minus float-right accor-plus-icon"></i>
              </a>

              <b-collapse id="Miscellaneous" data-parent="#accordion" accordion="Miscellaneous">
                <div class="pl-3 pb-2">
                  <b-form-group
                    class="mt-2 mr-4"
                    label-for="type"
                  >
                    <b-form-checkbox
                        v-model="p_criteria.singleIndices"
                      > 
                      Single Indices
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-model="p_criteria.compositeIndices"
                      > 
                      Composite Indices
                    </b-form-checkbox>
                    
                  </b-form-group>
                </div>
              </b-collapse>
            </div>

            <b-form-group class="">
              <button class=" mt-2 btn btn-c8 btn-block" @click="applyFilters">
                <span class="btn-content">
                  {{applyButtonTitle}}
                </span>
              </button>
              <button type="button" class=" mt-2 float-right btn btn-sm btn-link" @click="resetCriteria">
                Reset filters
              </button>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    
</template>