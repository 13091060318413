<script>
import Layout from "../../layouts/horizontal";
import { parseErrors } from '../../../helpers'
import Swal from "sweetalert2";
import http from '@/oauth-client'
import VueNumericInput from 'vue-numeric-input';
import moment from 'moment'
import FilterCard from "@/views/pages/indices/filter-card";
import simplebar from "simplebar-vue";



import {
   
} from "@/state/helpers";


// import 'bootstrap/scss/bootstrap.scss';
import {
   authComputed
} from "@/state/helpers";

export default {
  components: {
    Layout,
    VueNumericInput,
    FilterCard,
    simplebar
  },
  data() {
    
    return {
      showOnlyBookmarks: false,
      fieldsMap:{
        'name': this.$t('tables.indices.columns.name'),
        'index-type': this.$t('tables.indices.columns.indexType'),
        'market': this.$t('tables.indices.columns.market'),
        'provider':this.$t('tables.indices.columns.provider'),
        'asset-class': this.$t('tables.indices.columns.assetClass'),
        'instrument-type': this.$t('tables.indices.columns.instrumentType'),
        'rebalance-frequency': this.$t('tables.indices.columns.rebalanceFrequency'),
        'ytd': this.$t('tables.indices.columns.ytd'),
        'mtd': this.$t('tables.indices.columns.mtd'),
        'annualised-return': this.$t('tables.indices.columns.annualisedReturn'),
        'realised-volatility': this.$t('tables.indices.columns.realisedVolatility'),
        'downside-volatility': this.$t('tables.indices.columns.downsideVolatility'),
        'max-drawdown': this.$t('tables.indices.columns.maxDrawdown'),
        'winning-months': this.$t('tables.indices.columns.winningMonths'),
        'sharpe-ratio': this.$t('tables.indices.columns.sharpeRatio'),
        'sortino-ratio': this.$t('tables.indices.columns.sortinoRatio'),
        'calmar-ratio': this.$t('tables.indices.columns.calmarRatio')
      },
      mainChartYAxisLogarithmicType: false,

      filtersData: null,
      filterCriteria: null,

      bookmarks:{},
      indexViewHistory:[],
    
      items:[],
      errors:[],

      initializing: true,

      composedIndexSubmiting: false,
      screeningSubmiting: false,

      viewMode: 'grid',
      composedIndexBasicInfo: {
        name: '',
        description: ''
      },

      screening: this.createScreeningData(),

      composeIndexEnabled: false,
      composeIndexStep: 0,

      composeIndexItems: [],
      selectedPortfolioSolution: null,
      publicIndex: false,
      optimization: {
        walkForwardOptimizationEnabled: false,
        walkForwardOptimization: {
          inSamplePeriod:0,
          periodBetweenRebalancing: 0
        },
        targetVolatilityEnabled: false,
        targetVolatility: 0,
        startDate: null,
        endDate: null,
        solution:{
          values:{

          }
        }
      },

      statColumns: {
        market:{
          visible: true
        },
        provider:{
          visible: true
        },
        indexType:{
          visible: true
        },
        indexSubType:{
          visible: true
        },
        instrumentType:{
          visible: true
        },
        assetClass:{
          visible: true
        },
        rebalanceFrequency:{
          visible: true
        },
        mtd:{
          visible: true
        },
        ytd:{
          visible: true
        },

        annualisedReturn:{
          visible: true
        },
        realisedVolatility:{
          visible: true
        },
        downsideVolatility:{
          visible: true
        },
        maxDrawdown:{
          visible: true
        },
        winningMonths:{
          visible: true
        },
        sharpeRatio:{
          visible: true
        },
        sortinoRatio:{
          visible: true
        },
        calmarRatio:{
          visible: true
        },
      },
    

      dictionary:{
        rebalanceFrequencyMap:{

        },
        assetClassMap:{

        },
        instrumentTypeMap: {

        },
      },

      filters:[

      ],
      isBusy: false,

      keysLoading: false,
   
      keys:[],
  
      imageUrl: null,
      imageVisible: false,

      criteria: this.defaultCriteria(),

      productQuery: null,
      productsLoading: false,
      products:[],

      portfolioSolutions: [],
     
      currentPage: 1,
      perPage: 20,
      totalItems: 0,
      breadcrumbs: [
        {
          text: 'Marketplace',
          active: true
        }
      ],

      uploadVisible: false,
      uploadProcessing: false,
      selectedItemId: null
    };
  },
  created() {
    

    this.filters.push({
      nameAll: 'Select All',
      nameNotAll: 'Reset',
        func(elem) {
          return elem.selected;
        }
    });
    
    this.init();
    
  },
  watch: {
    mainChartYAxisLogarithmicType:{
      handler: function () {
        if(this.initializing === true) return;
        this.rebuildNetAssetValueGrowthCharts();
        this.saveUserData();
      }
    },
    viewMode:{
      handler: function () {
        if(this.initializing === true) return;
        this.saveUserData();
      }
    },
    statColumns: {
      deep: true,
      handler: function () {
        if(this.initializing === true) return;
        this.saveUserData();
      }
    },
    composedIndexBasicInfo:{
      deep: true,
      handler: function () {
        if(this.initializing === true) return;
        this.saveUserData();
      }
    },
    screening: {
      deep: true,
      handler: function () {
        if(this.initializing === true) return;
        this.saveUserData();
      }
    },
    optimization:{
      deep: true,
      handler: function () {
        if(this.initializing === true) return;

        if(this.optimization) {
          if(this.optimization.targetVolatility === 0 && this.optimization.targetVolatilityEnabled) {
            this.optimization.targetVolatility = 10;
          }
        }
       
        this.saveUserData();
      }
    },
    'criteria.bookmarksOnly':{
      handler: function () {
        if(this.initializing === true) return;
        this.saveUserData();
        this.applyFilters();
      }
    },
   'criteria.normalisedPerformance': {
      handler: function () {
        if(this.initializing === true) return;
        this.saveUserData();
        this.load();
      }
    },
    selectedPortfolioSolution: {
        deep: true,
        handler: function () {
          if(this.initializing === true) return;
          this.saveUserData();
        }
    },
    currentPage: {
      handler: function () {
        if(this.initializing === true) return;
        this.saveUserData();
        this.load();
      }
    },
    perPage: {
       handler: function () {
        if(this.initializing === true) return;
        this.saveUserData();
        this.load();
      }
    }
  },
  computed: {
    ...authComputed,
    filteredItems() {
      return this.showOnlyBookmarks 
        ? this.items.filter(x => this.bookmarks[x.id])
        : this.items;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.perPage);
    },
    isAdmin() {
      return this.currentUser.isAdmin;
    },
    title (){
      if(this.composeIndexEnabled) {
        return this.$t("pages.indices.composeIndexTitle");
      }else if(this.screening.enabled) {
        return this.$t("pages.indices.screeningTitle");
      } else {
        return 'Marketplace';
      }
    },
    isFilterEnabled() {
      if(this.isStatFiltered) return true;
      if(this.criteria.composedIndex) return true;
      if((this.criteria.portfolioSolution || []).length > 0) return true;
      if(this.criteria.assetClass.length > 0) return true;
      if(this.criteria.instrumentType.length > 0) return true;
      if(this.criteria.indexType.length > 0) return true;
      if(this.criteria.rebalanceFrequency.length > 0) return true;
      if(this.criteria.market.length > 0) return true;
      if(this.criteria.keyword) return true;
      if(this.criteria.provider.length > 0) return true;

      return false;
    },
    isStatFiltered() {
      if(this.criteria.mtd.from !== null && this.criteria.mtd.from !== undefined) return true;
      if(this.criteria.mtd.till !== null && this.criteria.mtd.till !== undefined) return true;
      
      if(this.criteria.ytd.from !== null && this.criteria.ytd.from !== undefined) return true;
      if(this.criteria.ytd.till !== null && this.criteria.ytd.till !== undefined) return true;

      if(this.criteria.annualisedReturn.from !== null && this.criteria.annualisedReturn.from !== undefined) return true;
      if(this.criteria.annualisedReturn.till !== null && this.criteria.annualisedReturn.till !== undefined) return true;

      if(this.criteria.realisedVolatility.from !== null && this.criteria.realisedVolatility.from !== undefined) return true;
      if(this.criteria.realisedVolatility.till !== null && this.criteria.realisedVolatility.till !== undefined) return true;

      if(this.criteria.downsideVolatility.from !== null && this.criteria.downsideVolatility.from !== undefined) return true;
      if(this.criteria.downsideVolatility.till !== null && this.criteria.downsideVolatility.till !== undefined) return true;
      
      if(this.criteria.maxDrawdown.from !== null && this.criteria.maxDrawdown.from !== undefined) return true;
      if(this.criteria.maxDrawdown.till !== null && this.criteria.maxDrawdown.till !== undefined) return true;

      if(this.criteria.winningMonths.from !== null && this.criteria.winningMonths.from !== undefined) return true;
      if(this.criteria.winningMonths.till !== null && this.criteria.winningMonths.till !== undefined) return true;

      if(this.criteria.sharpeRatio.from !== null && this.criteria.sharpeRatio.from !== undefined) return true;
      if(this.criteria.sharpeRatio.till !== null && this.criteria.sharpeRatio.till !== undefined) return true;

      if(this.criteria.sortinoRatio.from !== null && this.criteria.sortinoRatio.from !== undefined) return true;
      if(this.criteria.sortinoRatio.till !== null && this.criteria.sortinoRatio.till !== undefined) return true;

      if(this.criteria.calmarRatio.from !== null && this.criteria.calmarRatio.from !== undefined) return true;
      if(this.criteria.calmarRatio.till !== null && this.criteria.calmarRatio.till !== undefined) return true;


      return false;
    }
  },
  methods: {
    onFilterApply (criteria) {
      this.filterCriteria = criteria;
      this.currentPage = 1;
      this.saveUserData();
      this.load();
    },
    async loadFilters() {
      const resp = await http.get( 'api/indices/filters');
      this.filtersData = resp.data;

      let rebalanceFrequencyMap = {};
      let assetClassMap = {};
      let instrumentTypeMap = {};
      resp.data.rebalanceFrequency.forEach(x => rebalanceFrequencyMap[x.value] = x.text );
      resp.data.instrumentType.forEach(x => instrumentTypeMap[x.value] = x.text );
      resp.data.assetClass.forEach(x => assetClassMap[x.value] = x.text );
      this.rebalanceFrequencyMap = rebalanceFrequencyMap;
      this.assetClassMap = assetClassMap;
      this.instrumentTypeMap = instrumentTypeMap;
    },
    async loadBookmarks() {
       const resp = await http.get( 'api/indices/bookmarks');
       this.bookmarks = resp.data.reduce((obj,item)=> (obj[item.indexId] = true, obj),{});
    },
    clearRecintlyViewed() {
      localStorage.setItem('index_view_history', JSON.stringify([]));
      this.indexViewHistory = [];
    },
    showImage(url){
      this.imageUrl = url;
      this.imageVisible = true;
    },
    hideImage(){
      this.imageUrl = null;
      this.imageVisible = false;
    },
    handleFileUpload() {
      //debugger; // eslint-disable-line no-debugger
      const file = this.$refs['file'].files[0];
      let formData = new FormData(); 
      formData.append('file', file); 
      this.uploadProcessing = true;
      
      http.post( 'api/indices/' + this.selectedItemId + '/image', 
        formData, { headers: { 'Content-Type': 'multipart/form-data' } } )
        .then(() => {
          this.uploadProcessing = false;
          this.hideUploadFile();
          this.load();
        })
        .catch(() => {
       
          this.uploadProcessing = false;
          this.hideUploadFile();
          this.load();
        });
    },
    uploadFile(item) {
      this.uploadVisible = true;
      this.selectedItemId = item.id;
    },
    hideUploadFile() {
      this.uploadVisible = false;
      this.selectedItemId = null;
    },
    defaultCriteria(){
      return  {
        normalisedPerformance: false,
        sortBy: 'name',
        sortByDir: 'asc',
      };
    },
    resetCriteria() {
     this.criteria = this.defaultCriteria();
     this.saveUserData();
     this.applyFilters();
   },
    async init() {
      this.isBusy = true;
      this.initializing = true;

      await this.loadPortfolioSolutions();

      this.loadUserData(); // load user after solutions will loaded ONLY

      await this.loadFilters();
    },
    async loadPortfolioSolutions() {
      const resp = await http.get('api/portfolio-solutions');
      this.portfolioSolutions = resp.data;
    },
    startScreening() {
      this.screening = this.createScreeningData();
      this.screening.enabled = true;
      this.saveUserData();
    },
    cancelScreening () {
      this.screening = this.createScreeningData();
      this.saveUserData();
    },
    createScreeningData() {
      return {
          enabled: false,
          step: 0,
          screeningType: 'Correlations',
          years: 1,
          navOrReturn: 'Navs',
          targetVolatilityEnabled: false,
          targetVolatility: 0,
          items:[]
        }
    },
    addAllIndexToScreening(){
      this.items.forEach(x => this.addIndexToScreening(x));
    },
    isIndexAllowedForScreening(i) {
      if(!i.isComposed)
        return true;
      if(i.isComposed && !i.optimization || !i.optimization.calculation) {
        return true;
      }
      if(i.isComposed && i.optimization && i.optimization.calculation) {
        if(i.optimization.calculation.status === 'CalculationIsDone' || !i.optimization.calculation.status){
          return true;
        }
      }
      return false;
    },
    addIndexToScreening(i) {
      if(this.screening.items.find(a => a.id === i.id)) {
        return;
      }
      if(this.isIndexAllowedForScreening(i)) {
        let v =[... this.screening.items, { id: i.id, displayName: i.displayName }];
        v.sort((a, b) => a.displayName.localeCompare(b.displayName));
        this.screening.items = v;
        this.saveUserData();
      }
    },
    removeIndexFromScreening(i){
      let v =[... this.screening.items.filter(a => a.id !== i.id)];
      v.sort((a, b) => a.displayName.localeCompare(b.displayName));
      this.screening.items = v;
      this.saveUserData();
    },
    startComposeIndex() {
      this.errors = [];
      this.composeIndexEnabled = true;
      this.composeIndexItems = [];
      this.composedIndexBasicInfo = {
        name: '',
        description: ''
      };
      this.composeIndexStep = 0;
      this.optimization = {
        walkForwardOptimizationEnabled: false,
        walkForwardOptimization: {
          inSamplePeriod:0,
          periodBetweenRebalancing: 0
        },
        startDate: null,
        endDate: new Date(),
        targetVolatilityEnabled: false,
        targetVolatility: 0,
        solution:{
          values:{

          }
        }
      };
      this.selectedPortfolioSolution = this.portfolioSolutions.find(a => a.default) || this.portfolioSolutions[0] || null;
      this.saveUserData();
    },
    cancelComposeIndex () {
      this.optimization = null;
      this.composeIndexEnabled = false;
      this.composeIndexItems = [];
      this.composeIndexStep = 0;
      this.composedIndexBasicInfo = {
        name: '',
        description: ''
      };
      this.loadPortfolioSolutions();
      this.saveUserData();
    },
    selectPortfolioSolution() {
      this.optimization.solution.values = {};
      if(this.composeIndexItems.length > 0) {
        this.portfolioSolutions.forEach(a => {
          if(a.name === 'Weights') {
            a.positionParameters.forEach(x => {
              this.composeIndexItems.forEach(p => {
                this.optimization.solution.values[x.id + p.id] = parseFloat((1 / this.composeIndexItems.length).toPrecision(p.decimals));
              });
            });
            
          } else {
            a.positionParameters.forEach(x => {
              this.composeIndexItems.forEach(p => {
                this.optimization.solution.values[x.id + p.id] = x.defaultValue || 0;
              });
            });
          }
        
          a.solutionParameters.forEach(x => {
            this.optimization.solution.values[x.id] = x.defaultValue || 0;
          });
        });
        let minDate = null;
        
        this.composeIndexItems.forEach(index =>{
          if(minDate === null || minDate > index.minDate) {
            minDate = index.minDate;
          }
        });
        this.optimization.startDate = minDate;
        this.composeIndexStep = 1;
        this.saveUserData();
        this.$forceUpdate();
      }
     
    },
    backComposeIndex () {
      if(this.composeIndexStep > 0){
        this.composeIndexStep =  this.composeIndexStep - 1;
        this.saveUserData();
      }
    },
    selectAllComposeIndexPart() {
      const newItems = this.items.filter(i => !this.composeIndexItems.find(a => a.id === i.id));
      const prepNewItems = newItems.map(i => this.prepareObjFroComposeIndex(i));
      let v = [... this.composeIndexItems, ...prepNewItems];
      //console.log(v)
      v.sort((a, b) => (a.displayName || '').localeCompare(b.displayName || ''));
      this.composeIndexItems = v;
      this.saveUserData();
    },
    prepareObjFroComposeIndex(i) {
      let minDate = null;
      (i.netAssetValueGrowthCharts || []).forEach(chartData =>{
        (chartData.points || []).forEach(point => {
          const dt = moment(point.dateTime);
          if(minDate === null || minDate > dt) {
            minDate = dt;
          }
        });
      });
      //console.log(i);
      return { id: i.id, displayName: i.displayName, minDate: (minDate ? minDate.toDate() : null) };
    },
    selectComposeIndexPart(i) {
      if(this.composeIndexItems.find(a => a.id === i.id)) {
        return;
      }
     
      let v =[... this.composeIndexItems, this.prepareObjFroComposeIndex(i)];
      v.sort((a, b) => a.displayName.localeCompare(b.displayName));
      this.composeIndexItems = v;
      this.saveUserData();
    },
    removeComposeIndexPart(i){
      let v =[... this.composeIndexItems.filter(a => a.id !== i.id)];
      v.sort((a, b) => a.displayName.localeCompare(b.displayName));
      this.composeIndexItems = v;
      this.saveUserData();
    },
    inputComposedIndexName () {
      if(!this.optimization.startDate){
        this.errors = ['Optimmization date range is required.'];
        return;
      }
      this.composeIndexStep = 2;
      this.saveUserData();
    },
    submitComposedIndex () {
      if(!this.composedIndexBasicInfo.name)
        return;
        if(!this.optimization.startDate){
          this.errors = ['Optimmization date range is required.'];
          return;
        }
        const dto = {
           
          name: this.composedIndexBasicInfo.name,
          displayname: this.composedIndexBasicInfo.name,
          description: this.composedIndexBasicInfo.description,
          isComposed: true,
          publicIndex: this.publicIndex,
          children: this.composeIndexItems.map(a => a.id),

          optimization: {
            walkForwardOptimizationEnabled: this.optimization.walkForwardOptimizationEnabled,
            walkForwardOptimization: {
              inSamplePeriod: this.optimization.walkForwardOptimization.inSamplePeriod,
              periodBetweenRebalancing:  this.optimization.walkForwardOptimization.periodBetweenRebalancing
            },
            targetVolatilityEnabled: this.optimization.targetVolatilityEnabled,
            targetVolatility: this.optimization.targetVolatility,
            solution: this.selectedPortfolioSolution.id,
            parameters:[],
            startDate: this.optimization.startDate,
            endDate: this.optimization.endDate,
            shouldBeRecalculated: true,
          }
        };
        this.composeIndexItems.forEach(i =>
          {
            this.selectedPortfolioSolution.positionParameters.forEach(p =>{
              dto.optimization.parameters.push({ solutionParameter: p.id, index: i.id, value: this.optimization.solution.values[p.id + i.id] });
            });
          }
        );
        this.selectedPortfolioSolution.solutionParameters.forEach(p =>{
              dto.optimization.parameters.push({ solutionParameter: p.id, value: this.optimization.solution.values[p.id] });
          });
        this.composedIndexSubmiting = true;
        
        const req = http.post('api/indices', dto);
        req.then((resp) => {
          this.composedIndexSubmiting = false;
          this.cancelComposeIndex();
          this.load();
          this.$router.push({ name: "index-view", params: { id: resp.data.id } });
        })
        .catch(error => {
          this.composedIndexSubmiting = false;
          this.errors = parseErrors(error);
        });
    },
    submitScreening () {
      const dto = {
        ...this.screening,
        indices: this.screening.items.map(x => x.id),
        targetVolatility: this.screening.targetVolatility || 0,
        items: undefined
      };
       
      this.screeningSubmiting = true;
        
      const req = http.post('api/screening', dto);
      req.then((resp) => {
        this.screeningSubmiting = false;
        this.cancelScreening();
        this.load();
        this.$router.push({ name: "screening", params: { id: resp.data.id } });
      })
      .catch(error => {
        this.screeningSubmiting = false;
        this.errors = parseErrors(error);
      });
    },
    loadUserData() {
      let dataStr = localStorage.getItem('indices_list_data');
     
      //debugger; // eslint-disable-line no-debugger
      if(dataStr) {
        const data = JSON.parse(dataStr);
       
        // this.currentPage = data.currentPage;
        this.currentPage = data.currentPage;
        this.viewMode = data.viewMode;
        this.perPage = data.perPage || 20;
        
        if(!data.statColumns.indexSubType) {
          data.statColumns.indexSubType = {
            visible: true,
          };
        }
        this.statColumns = data.statColumns;
        this.screening = data.screening || this.createScreeningData();     
        this.criteria = data.criteria;     
        this.composeIndexEnabled = data.composeIndexEnabled || false;  
        this.composeIndexItems = data.composeIndexItems || [];
        this.composeIndexStep = data.composeIndexStep || 0;
        this.mainChartYAxisLogarithmicType = data.mainChartYAxisLogarithmicType || false,
        this.composedIndexBasicInfo = data.composedIndexBasicInfo || {
          name: '',
          description: ''
        };
        this.optimization = data.optimization || {
          startDate: null,
          endDate: new Date(),
          walkForwardOptimizationEnabled: false,
          walkForwardOptimization: {
            inSamplePeriod:0,
            periodBetweenRebalancing: 0
          },
          targetVolatilityEnabled: false,
          targetVolatility: 0,
          solution: {
            values: {

            }
          }
        };
      
        if(data.selectedPortfolioSolution) {
          this.selectedPortfolioSolution = this.portfolioSolutions.find(a => a.id === data.selectedPortfolioSolution.id);
        }
        
        if(!this.selectedPortfolioSolution) {
          this.selectedPortfolioSolution = this.portfolioSolutions[0];
        }  
      }
    
    },
    saveUserData() {
      const data = {
        version: 1,
        currentPage: this.currentPage,
        perPage: this.perPage,
        viewMode: this.viewMode,
        statColumns: this.statColumns,
        criteria: this.criteria,
        composeIndexEnabled: this.composeIndexEnabled,
        composeIndexItems: this.composeIndexItems,
        composeIndexStep: this.composeIndexStep,
        selectedPortfolioSolution: this.selectedPortfolioSolution,
        optimization: this.optimization,
        screening: this.screening,
        composedIndexBasicInfo: this.composedIndexBasicInfo,
        mainChartYAxisLogarithmicType: this.mainChartYAxisLogarithmicType
      };
      localStorage.setItem('indices_list_data', JSON.stringify(data));
    },
    async applyFilters(){
      this.currentPage = 1;
      this.saveUserData();
      this.load();
    },
    async loadKeys(){
    
      this.selectedKeyValue = null;
      this.selectedKey = null;
      const resp = await http.get(`api/indices/system-values/keys`);
      this.keys = resp.data;
    
    },
    prepareNetAssetValueGrowthCharts(data) {
     const colors = ['#434348', '#7cb5ec', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'];
      let i = 0;
    
     let chartSeries =  data.netAssetValueGrowthCharts;
      if(!chartSeries)
          return;

      chartSeries.forEach(x => x.chartVisible = true);
      chartSeries
        .filter(x => x.indexId === data.id && !x.isLive)
        .forEach(x => x.color = "#FE6C2D");
      chartSeries
        .filter(x => x.indexId === data.id && x.isLive)
        .forEach(x => x.color = "#7d096d");

      chartSeries
        .filter(x => x.indexId !== data.id)
        .forEach(x => {
          x.color = colors[i++];
          if(i === colors.length) i = 0;
        });
    },
   
    async addBookmark(indexId) {
      let o = {...this.bookmarks};
      o[indexId] = true;
      this.bookmarks = o;
     
      await http.post('api/indices/' + indexId + '/bookmarks');
    },
    async removeBookmark(indexId){
      let o = {...this.bookmarks};
      o[indexId] = false;
      this.bookmarks = o;
      await http.delete('api/indices/' + indexId + '/bookmarks');
    },
    createChartOptions(element) {
      let chartSeries =  element.netAssetValueGrowthCharts || [];
      if(!chartSeries)
          return;
        
      let seriesOptions = [];
      for(let i = 0; i < chartSeries.length; i++) {
        const source = chartSeries[i];
        if(source.indexId === element.id)
        {
          seriesOptions.push({
            name: source.title + (source.isLive ? ' [Live]' : ''),
            label: source.title,
            data: (source.points || []).map(x => [x.timeStamp, x.value]),
            color: source.color,
            lineWidth: source.isLive ? 3 : 1
          });
        }
      }

      return {
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        accessibility:{
          enabled: false
        },
        annotations: {
          visible: false
        },
        boost:{
          enabled: false
        },
        exporting:{
          enabled: false
        },
        navigator:{
          enabled: false
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false
            }
          },
        },
         
        yAxis: {
          type: this.mainChartYAxisLogarithmicType ? 'logarithmic' : null,
          labels: {
            formatter: function () {
              return (this.value > 0 ? '' : '') + this.value;
            }
          },
          plotLines: [{
            value: 0,
              width: 2,
              color: 'silver'
          }]
        },
        
        tooltip: {
          pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
          valueDecimals: 2
        },
        colors: ['#434348', '#7cb5ec', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'],
        series: seriesOptions
      };
    },
    rebuildNetAssetValueGrowthCharts() {
      this.items.forEach(x => {
        x.chartOptions = this.createChartOptions(x);
      });
    },
    async load(silence)  {
     
      let dataStr = localStorage.getItem('index_view_history');
      
      let indexViewHistory = [];
      if(dataStr) {
        indexViewHistory = JSON.parse(dataStr);
      } else {
        indexViewHistory = [];
      }
      this.indexViewHistory = indexViewHistory.reverse();

      if(silence !== true) {
        this.isBusy = true;
      }

      const currentPage = this.currentPage;
      let sv = {};
      if(this.criteria.selectedKey){
        sv[this.criteria.selectedKey] = this.criteria.selectedKeyValue;
      }
      await this.loadBookmarks();
      const resp = await http.post(`api/indices/search`, {
        take: this.perPage,
        skip: this.perPage * (currentPage - 1),
        keyword:  this.filterCriteria.keyword,
        name: this.filterCriteria.name,
        systemValues:sv,
        providers: (this.filterCriteria.provider || []).map(x => x),
        bookmarksOnly:  this.filterCriteria.bookmarksOnly || false,
        portfolioSolutions: (this.filterCriteria.portfolioSolution || []).map(x => x),
        markets: this.filterCriteria.market,
        indexTypes:  this.filterCriteria.indexType,
        indexSubTypes: this.filterCriteria.indexSubType,
        instrumentTypes: this.filterCriteria.instrumentType,
        assetClasses: this.filterCriteria.assetClass,
        rebalanceFrequency: this.filterCriteria.rebalanceFrequency,
        sortBy: this.criteria.sortBy,
        sortByDir: this.criteria.sortByDir,
        mtd: this.filterCriteria.mtd,
        ytd: this.filterCriteria.ytd,
        annualisedReturn: this.filterCriteria.annualisedReturn,
        realisedVolatility: this.filterCriteria.realisedVolatility,
        downsideVolatility: this.filterCriteria.downsideVolatility,
        maxDrawdown: this.filterCriteria.maxDrawdow,
        winningMonths:this.filterCriteria.winningMonths,
        sharpeRatio: this.filterCriteria.sharpeRatio,
        sortinoRatio: this.filterCriteria.sortinoRatio,
        calmarRatio: this.filterCriteria.calmarRatio,
        normalisedPerformance: this.criteria.normalisedPerformance,
        singleIndices: this.filterCriteria.singleIndices,
        compositeIndices: this.filterCriteria.compositeIndices,
        onlyMy: this.filterCriteria.onlyMy
      });
     
      resp.data.items.forEach(element => {
       
        this.prepareNetAssetValueGrowthCharts(element);
        element.chartOptions = this.createChartOptions(element);
      });

      this.items = resp.data.items;
      this.totalItems = resp.data.total;
     
      if(silence !== true) {
        this.isBusy = false;
      } 
      this.initializing = false;
    },
    confirmDelete(item) {
      Swal.fire({
        title: this.$t("common.areYouSure"),
        text: this.$t("common.youCannotRevertOp"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("common.yesDelete")
      }).then(result => {
        if (result.value) {
         this.handleDelete(item);
        }
      });
    },
    handleDelete(item) {
     http.delete(`api/indices/${item.id}`).then(() => {
        Swal.fire(this.$t("common.deleted"), this.$t("common.recWasDeleted"), "success");
        this.load();
      })
      .catch(error => {
        this.errors = parseErrors(error);
          Swal.fire({
            title: 'Unable to delete',
            text: this.errors[0],
            icon: "warning",
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: 'Ok'
          });
      });
    },
    getImageUrl(item){
      return item ? process.env.VUE_APP_BASE_URL + "/api/indices/" + item.id + "/image" : "/";
    },
    sortByColumn(column){
      if(this.criteria.sortBy === column)
        return;

      this.criteria.sortBy = column;
      this.saveUserData();
      this.load();
    },
    sortByDir(dir) {
      if(this.criteria.sortByDir === dir)
        return;
      this.criteria.sortByDir = dir;
      this.saveUserData();
      this.load();
    },
    sortBy(column) {
      if(this.criteria.sortBy === column) {
        if(this.criteria.sortByDir === 'asc') {
          this.criteria.sortByDir = 'desc';
        } else {
          this.criteria.sortByDir = 'asc';
        }
      } else {
        this.criteria.sortBy = column;
        this.criteria.sortByDir = 'asc';
      }
      this.saveUserData();
      this.load();
    },
    showList(){
      this.viewMode = 'list';
    },
    showGrid(){
      this.viewMode = 'grid';
      this.rebuildNetAssetValueGrowthCharts();
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0">
            <span  class="title-content">
              {{ title }} <i v-if="isBusy"  class="fa fa-spin fa-spinner ml-2"></i>
            </span>
          </h4>
          <div class="page-title-  d-none d-lg-block">
            <b-breadcrumb v-if="!composeIndexEnabled" :items="breadcrumbs" class="m-0"></b-breadcrumb>
            
          </div>
        </div>
      </div>
    </div>

    <div class="card cardc8" v-if="screening.enabled">
      <div class="card-content">
        <div class="card-body">
          <div v-if="screening.step === 0">
            <h4 class="card-title mb-4">Select indices for screening</h4>
          
            <div class="alert alert-info" v-if="screening.items.length === 0">Please select indices below.</div>
            <simplebar style="max-height: 200px" class=" mb-4">
              <table class="table table-striped table-sm table-hover ">
                <tbody>
                  <tr v-for="i in screening.items" :key="i.id">
                    <td>{{i.displayName}}</td>
                    <td style="max-width:30px; width:30px;">
                      <a href="javascript:void(0)" @click="removeIndexFromScreening(i)">
                        <i class="fa fa-times"/>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </simplebar>
            <div class="row"> 
              <div class="col-lg-4"> 
                <b-form-group>
                  <select class="form-control" v-model="screening.screeningType" @change="screening.year = 1">
                    <option value="Correlations">Correlations</option>
                    <option value="RollingCorrelations">Rolling correlations</option>
                    <option value="PerformanceStatistics">Performance statistics</option>
                    <option value="PerformanceData">Performance data</option>
                  </select>
                </b-form-group>
              </div>
              <div class="col-lg-4"> 
                <b-form-group>
                  <select class="form-control" v-model="screening.years">
                    <option :value="1">1 Year</option>
                    <option :value="3">3 Years</option>
                    <option :value="5" v-if="screening.screeningType !== 'RollingCorrelations'">5 Years</option>
                    <option :value="10">10 Years</option>
                  </select>
                </b-form-group>
              </div>
              <div class="col-lg-4" v-if="screening.screeningType === 'PerformanceStatistics' || screening.screeningType === 'PerformanceData'"> 
                <b-form-group>
                  <select class="form-control" v-model="screening.navOrReturn">
                    <option value="Navs">Navs</option>
                    <option value="Return">Return (%)</option>
                  </select>
                </b-form-group>
              </div>
            
            </div>
            <div class="row"> 
              <div class="col-lg-4" v-if="screening.screeningType === 'PerformanceStatistics' || screening.screeningType === 'PerformanceData'"> 
                  <b-form-group 
                    label="Target Volatility (%)">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1"><input type="checkbox" v-model="screening.targetVolatilityEnabled"/></span>
                      <input type="text" class="form-control" v-model.number="screening.targetVolatility">
                    </div>
                  </b-form-group>
                </div>
              </div>
          </div>
        </div>
        <div class="mr-3 ml-3">
          <div class="btn-group float-left" >
            <button type="button"  class="btn btn-outline-secondary  mb-2"
            @click="cancelScreening">Cancel</button>
          </div>
          <!-- <div class="btn-group float-right" v-if="screening.step === 0" >
            <button type="button"   class="btn btn-primary mb-2" :disabled="screening.items.length === 0"> Next<i class="fa fa-chevron-right ml-1"></i></button>
          </div> -->
          <div class="btn-group float-right">
            <button type="button" 
              @click="submitScreening"  
              
              class="btn btn-success mb-2" 
              :disabled="screening.items.length === 0 || screeningSubmiting"> 
              <i class="fa fa-save mr-1" :disabled="!screeningSubmiting"></i>
              <i class="fa fa-spin fa-spinner mr-1"  v-if="screeningSubmiting"></i>
              Create
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card cardc8" v-if="composeIndexEnabled">
      <div class="card-content">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <b-alert
                variant="danger"
                class="mt-3"
                v-if="errors.length > 0"
                show
                dismissible
                >
                <ul>
                  <li v-for="e in errors" :key="e">{{e}}</li>
                </ul>
              </b-alert>
            </div>
          </div>
          <div v-if="composeIndexStep === 0">
            <h4 class="card-title mb-4">Select indices</h4>
           
            <div class="alert alert-info" v-if="composeIndexItems.length === 0">Please select indices below.</div>
            <simplebar style="max-height: 200px">
              <table class="table table-striped table-sm table-hover">
                <tbody>
                  <tr v-for="i in composeIndexItems" :key="i.id">
                    <td>{{i.displayName}}</td>
                    <td style="max-width:30px; width:30px;">
                      <a href="javascript:void(0)" @click="removeComposeIndexPart(i)">
                        <i class="fa fa-times"/>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </simplebar>
          </div>
          <div v-if="composeIndexStep === 1">
            <div class="row">
            
              <div class="col-lg-4">
                <h4 class="card-title  mb-4">Portfolio Solution</h4>
              
                <select class="form-control" v-model="selectedPortfolioSolution">
                  <option v-for="sol in portfolioSolutions" :key="sol.id" :value="sol">{{sol.title}}</option>
                </select>
                <div v-if="selectedPortfolioSolution" >
                  <p class="alert alert-info mt-2" v-html="selectedPortfolioSolution.descriptionHtml">
                    
                  </p>
                  <div class="mt-2">
                    <div class="form-group" v-for="p in (selectedPortfolioSolution || {}).solutionParameters || []" :key="p.id">
                      <label>{{p.title}}</label>
                      <div>
                        <vue-numeric-input  
                          v-model="optimization.solution.values[p.id]" 
                          :min="p.min" 
                          :max="p.max" 
                          :step="(p.type !== 'Float' ? 1 : (p.decimals === 1 ? 0.1 : 0.01))"
                          :precision="p.decimals">
                        </vue-numeric-input>
                      </div>
                      
                      
                    </div>
                  </div>
                </div>
                
                <h4 class="card-title mt-4">
                  <input type="checkbox" class="mr-2" v-model="optimization.walkForwardOptimizationEnabled"/> 
                  Walk Forward Optimisation
                </h4>
              
                <div class="form-group" v-if="optimization.walkForwardOptimization">
                  <label>
                    In Sample Period (Months)
                  </label>
                  <div>
                    <vue-numeric-input  
                      :disabled="!optimization.walkForwardOptimizationEnabled"
                      v-model="optimization.walkForwardOptimization.inSamplePeriod" 
                      :step="1"
                      :precision="0">
                    </vue-numeric-input>
                  </div>
                  
                </div>
                <div class="form-group" v-if="optimization.walkForwardOptimization">
                  <label>
                    Period between Rebalancing (Months)
                  </label>
                  <div>
                    <vue-numeric-input 
                      :disabled="!optimization.walkForwardOptimizationEnabled"
                      v-model="optimization.walkForwardOptimization.periodBetweenRebalancing" 
                      :step="1"
                      :precision="0">
                    </vue-numeric-input>
                  </div>
                
                </div>

                <h4 class="card-title mt-4 ">
                  <input type="checkbox" class="mr-2" v-model="optimization.targetVolatilityEnabled"/> 
                  Target Volatility (%):
                </h4>
              
                <div class="form-group">
                  
                  <div>
                    <vue-numeric-input 
                      :disabled="!optimization.targetVolatilityEnabled"
                      v-model="optimization.targetVolatility" 
                      :min="0"
                      :max="100"
                      :step="1"
                      :precision="0">
                    </vue-numeric-input>
                  </div>
                
                </div>
                
                <h4 class="card-title mt-4 ">
                  Optimization date
                </h4>
              
                <div class="form-group">
                  <label>
                    Start
                  </label>
                  <div>
                    <b-form-datepicker 
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      today-button
                      reset-button
                      close-button
                      v-model="optimization.startDate" 
                      class="mb-2">
                    </b-form-datepicker>
                  </div>
                
                </div>
                <div class="form-group">
                  <label>
                    End
                  </label>
                  <div>
                    <b-form-datepicker 
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      today-button
                      reset-button
                      close-button
                      v-model="optimization.endDate" class="mb-2">
                    </b-form-datepicker>
                  </div>
                
                </div>
              </div>
              <div class="col-lg-8">
                <h4 class="card-title mb-4">Selected indices</h4>
                
                <simplebar style="max-height: 450px">
                  <table class="table table-striped table-sm table-hover">
                    <thead>
                      <tr>
                        <th class="">Index name</th>
                        <th v-for="p in (selectedPortfolioSolution || {}).positionParameters || []" :key="p.id">
                          {{p.title}}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="i in composeIndexItems" :key="i.id">
                        <td class="">{{i.displayName}}</td>
                        <td v-for="p in (selectedPortfolioSolution || {}).positionParameters || []" :key="p.id">
                          <vue-numeric-input 
                          
                            v-model=" optimization.solution.values[p.id + i.id]"
                            :min="p.min" 
                            :max="p.max" 
                            :step="(p.type !== 'Float' ? 1 : (p.decimals === 1 ? 0.1 : 0.01))"
                            :precision="p.decimals">
                          </vue-numeric-input>
                          
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </simplebar>
              </div>
            </div>
          
          </div>

          <div v-if="composeIndexStep === 2">
            <h4 class="card-title mb-4">Index name and description</h4>
          

            <div class="form-group">
              <label>
                Name [* required field]
              </label>
              <div>
                <input type="text" class="form-control" v-model="composedIndexBasicInfo.name" :disabled="composedIndexSubmiting"/>
              </div>
            </div>
            
            <div class="form-group">
              <label>
                Description
              </label>
              <div>
                <textarea type="text" class="form-control" v-model="composedIndexBasicInfo.description" :disabled="composedIndexSubmiting">
                </textarea>
              </div>
            </div>
            <div class="form-group" v-if="isAdmin">
              
              <input id="publicIndex" type="checkbox" class="mr-2" v-model="publicIndex"/>
              <label for="publicIndex">
                Public Index
              </label>
            </div>
          </div>
        </div>
      
        <div class="mr-3 ml-3">
          <div class="row">
            <div class="col">
              <div class="btn-group float-left" >
                <button type="button"  class="btn btn-outline-secondary  mb-2" @click="cancelComposeIndex">Cancel</button>
              </div>
              <div class="btn-group float-right" v-if="composeIndexStep == 0">
                <button type="button"   class="btn btn-primary mb-2" @click="selectPortfolioSolution" :disabled="composeIndexItems.length == 0"> Next <i class="fa fa-chevron-right ml-1"></i></button>
              </div>
              <div class="btn-group float-right" v-if="composeIndexStep == 1">
              <button type="button" :disabled="!selectedPortfolioSolution"  class="btn btn-primary mb-2" @click="inputComposedIndexName"> Next <i class="fa fa-chevron-right ml-1"></i></button>
              </div>
              <div class="btn-group float-right" v-if="composeIndexStep == 2">
                <button type="button"  class="btn btn-success mb-2" @click="submitComposedIndex" :disabled="!composedIndexBasicInfo.name || composedIndexSubmiting"> 
                  <i class="fa fa-save mr-1" v-if="!composedIndexSubmiting"></i>
                  <i class="fa fa-spin fa-spinner mr-1"  v-if="composedIndexSubmiting"></i>
                  Create
                </button>
              </div>
              <div class="btn-group float-right mr-4" v-if="composeIndexStep > 0">
                <button type="button"  class="btn btn-secondary mb-2" @click="backComposeIndex()" :disabled="composedIndexSubmiting" > 
                  <i class="fa fa-chevron-left mr-1"></i> Back 
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end card-body -->
    </div>
    
    <div class="row" v-if="(!screening.enabled || screening.step === 0) || (composeIndexStep === 0 || (!composeIndexEnabled))">
      <div class="col-xl-3 col-lg-4">
        <filter-card 
          v-if="filtersData"
          :values="filtersData" 
          :persistent="true" 
          @apply="onFilterApply"
          @load="onFilterApply"
          :reset-button-no-action="false"
          apply-button-title="Apply"
        >
        </filter-card> 
        <div v-if="isBusy" style="position:absolute;left:0; top:0; bottom:0; right:0;background:white;opacity:0.5;"></div>
      
        <div class="card cardc8 mt-4" v-if="indexViewHistory.length > 0">
          <div class="card-content">
            <div class="card-header bg-transparent">
              <div class="row">
                <div class="col">
                    <h5 class="mb-0 float-left">Recently viewed</h5>
                  <button type="button" class="float-right btn btn-sm btn-link" @click="clearRecintlyViewed">
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body p-0 pt-4">

            <ul  v-for="v in indexViewHistory" :key="v.id">
              <li>
                <router-link
                :to="{ name: 'index-view', params: { id: v.id}}"
               
                style="display: block"
              >
                {{v.displayName}}
                
              </router-link>
              </li>
              
            </ul>
          </div>
        </div>
      </div>   
      <div class="col-xl-9 col-lg-8">
        <div class="card cardc8" style="position:relative;">
          <div class="card-content">
            <div class="card-body">
              <div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="btn-toolbar mb-2">
                      <div>
                        <div class="btn-group  mb-2 mr-1 mb-sm-0" v-if="isAdmin && !composeIndexEnabled">
                          <a href="indices/new/edit"  class="btn btn-c8 " >
                          <span class="btn-content">
                            <i class="fa fa-plus mr-2"></i>New index
                          </span>
                          </a>
                          <button type="button"  class="btn btn-c8 revers" @click="startComposeIndex">
                              <span class="btn-content">
                                <i class="fa fa-plus mr-2"></i>Combine 
                              </span>
                            </button>
                        </div>
                        <div class="btn-group  mb-2 mr-1 mb-sm-0" v-if="!isAdmin && !composeIndexEnabled">
                          <button type="button"  class="btn btn-c8" @click="startComposeIndex">
                              <span class="btn-content">
                                <i class="fa fa-plus mr-2"></i>Combine 
                              </span>
                            </button>
                        </div>

                        <div class="btn-group mb-2 mr-1 mb-sm-0 ml-2" v-if="!composeIndexEnabled && !screening.enabled">
                          <button type="button"  class="btn btn-c8" @click="startScreening">
                            <span class="btn-content">
                             <i class="fas fa-chart-line mr-2"></i>Screening
                            </span>
                          </button>
                          <router-link  v-if="!composeIndexEnabled && !screening.enabled" :to="{ name: 'screening-history'}" class="btn btn-c8 revers"> 
                            <span class="btn-content">
                           <i class="fas  fa-history mr-2"></i>History
                            </span>
                          </router-link> 
                        </div>
                        
                        <div class="btn-group mb-2 mr-2 mb-sm-0 ml-2" v-if="!composeIndexEnabled && !screening.enabled">
                          
                        </div>
                        
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 text-right">
                    
                    <div class="btn-group mr-2 mb-2 mb-sm-0">
                      <button type="button" class="btn btn-outline" :class="{'btn-outline-primary': viewMode === 'list'}" @click="showList()">
                        <i class="fa fa-list"></i>
                      </button>
                    </div>
                    <div class="btn-group mr-4 mb-2 mb-sm-0">
                      <button type="button" class="btn btn-outline " :class="{'btn-outline-primary': viewMode === 'grid'}" @click="showGrid()">
                        <i class="fa fa-th"></i>
                        </button>
                    </div> 
                    <div class="btn-group mr-2 mb-2 mb-sm-0">
                    
                      <b-dropdown left variant="white">
                        <template v-slot:button-content>
                          <i class="fa fa-columns m-0 text-muted font-size-20"></i>
                        </template>
                        <div class="dropdown-item bg-white text-body">
                          <div class="form-group"><input type="checkbox" v-model="statColumns.indexType.visible" id="stateColumns_indexType"/>
                            <label for="stateColumns_indexType" class="ml-2">{{$t('tables.indices.columns.indexType')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.indexSubType.visible" id="stateColumns_indexSubType"/>
                            <label for="stateColumns_indexSubType" class="ml-2">Index Sub Type</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.market.visible" id="stateColumns_market"/>
                            <label for="stateColumns_market" class="ml-2">{{$t('tables.indices.columns.market')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.provider.visible" id="stateColumns_provider"/>
                            <label for="stateColumns_provider" class="ml-2">{{$t('tables.indices.columns.provider')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.assetClass.visible" id="stateColumns_assetClass"/>
                            <label for="stateColumns_assetClass" class="ml-2">{{$t('tables.indices.columns.assetClass')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.instrumentType.visible" id="stateColumns_instrumentType"/>
                            <label for="stateColumns_instrumentType" class="ml-2">{{$t('tables.indices.columns.instrumentType')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.rebalanceFrequency.visible" id="stateColumns_rebalanceFrequency"/>
                            <label for="stateColumns_rebalanceFrequency" class="ml-2">{{$t('tables.indices.columns.rebalanceFrequency')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.ytd.visible" id="stateColumns_ytd"/>
                            <label for="stateColumns_ytd" class="ml-2">{{$t('tables.indices.columns.ytd')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.mtd.visible" id="stateColumns_mtd"/>
                            <label for="stateColumns_mtd" class="ml-2">{{$t('tables.indices.columns.mtd')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.annualisedReturn.visible" id="stateColumns_annualisedReturn"/>
                            <label for="stateColumns_annualisedReturn" class="ml-2">{{$t('tables.indices.columns.annualisedReturn')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.realisedVolatility.visible" id="stateColumns_realisedVolatility"/>
                            <label for="stateColumns_realisedVolatility" class="ml-2">{{$t('tables.indices.columns.realisedVolatility')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.downsideVolatility.visible" id="stateColumns_downsideVolatility"/>
                            <label for="stateColumns_downsideVolatility" class="ml-2">{{$t('tables.indices.columns.downsideVolatility')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.maxDrawdown.visible" id="stateColumns_maxDrawdown"/>
                            <label for="stateColumns_maxDrawdown" class="ml-2">{{$t('tables.indices.columns.maxDrawdown')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.winningMonths.visible" id="stateColumns_winningMonths"/>
                            <label for="stateColumns_winningMonths" class="ml-2">{{$t('tables.indices.columns.winningMonths')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.sharpeRatio.visible" id="stateColumns_sharpeRatio"/>
                            <label for="stateColumns_sharpeRatio" class="ml-2">{{$t('tables.indices.columns.sharpeRatio')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.sortinoRatio.visible" id="stateColumns_sortinoRatio"/>
                            <label for="stateColumns_sortinoRatio" class="ml-2">{{$t('tables.indices.columns.sortinoRatio')}}</label>
                          </div>
                          <div class="form-group"><input type="checkbox" v-model="statColumns.calmarRatio.visible" id="stateColumns_calmarRatio"/>
                            <label for="stateColumns_calmarRatio" class="ml-2">{{$t('tables.indices.columns.calmarRatio')}}</label>
                          </div>
                        </div>
                      </b-dropdown>

                      <b-dropdown left variant="white" ref="sortByDropdown">
                        <template v-slot:button-content>
                          <b class="mr-2" style="">{{fieldsMap[criteria.sortBy]}}</b>
                          <i class="fas text-muted fa-sort-alpha-up mr-2" v-if=" criteria.sortByDir === 'asc'"/>
                          <i class="fas text-muted fa-sort-alpha-down mr-2" v-if=" criteria.sortByDir !== 'asc'"/>
                          <i class="fas fa-caret-down"/>
                        </template>
                        <b-dropdown-form>
                          <select class="form-control " 
                            :disabled="isBusy"
                            :value="criteria.sortBy" 
                            style="width:200px" 
                            @change="($event) => sortByColumn($event.target.value)"
                          >
                            <option value="name">Name</option>
                            <option value="index-type">{{$t('tables.indices.columns.indexType')}}</option>
                            <option value="market">{{$t('tables.indices.columns.market')}}</option>
                            <option value="provider">{{$t('tables.indices.columns.provider')}}</option>
                            <option value="asset-class">{{$t('tables.indices.columns.assetClass')}}</option>
                            <option value="instrument-type">{{$t('tables.indices.columns.instrumentType')}}</option>
                            <option value="rebalance-frequency">{{$t('tables.indices.columns.rebalanceFrequency')}}</option>
                            <option value="ytd">{{$t('tables.indices.columns.ytd')}}</option>
                            <option value="mtd">{{$t('tables.indices.columns.mtd')}}</option>
                            <option value="annualised-return">{{$t('tables.indices.columns.annualisedReturn')}}</option>
                            <option value="realised-volatility">{{$t('tables.indices.columns.realisedVolatility')}}</option>
                            <option value="downside-volatility">{{$t('tables.indices.columns.downsideVolatility')}}</option>
                            <option value="max-drawdown">{{$t('tables.indices.columns.maxDrawdown')}}</option>
                            <option value="winning-months">{{$t('tables.indices.columns.winningMonths')}}</option>
                            <option value="sharpe-ratio">{{$t('tables.indices.columns.sharpeRatio')}}</option>
                            <option value="sortino-ratio">{{$t('tables.indices.columns.sortinoRatio')}}</option>
                            <option value="calmar-ratio">{{$t('tables.indices.columns.calmarRatio')}}</option>
                          </select>
                          <div class="row">
                            <div class="col  mt-2">
                              <button type="button" class="btn btn-block" 
                              :disabled="isBusy"
                              @click="sortByDir('asc')"
                                :class="{' btn-outline-secondary': criteria.sortByDir !== 'asc', ' btn-secondary': criteria.sortByDir === 'asc'}"
                                >
                                <i class="fas fa-sort-alpha-up mr-2"></i>Asc
                              </button>
                            </div>
                            <div class="col mt-2">
                              <button type="button" class="btn btn-outline-secondary btn-block"
                              :disabled="isBusy"
                                @click="sortByDir('desc')"
                              :class="{' btn-outline-secondary': criteria.sortByDir !== 'desc', ' btn-secondary': criteria.sortByDir === 'desc'}"
                                >
                                <i class="fas fa-sort-alpha-down mr-2"></i>Desc
                              </button>
                            </div>
                          </div>
                        </b-dropdown-form>
                        <div class="p-2 pl-4 pr-4">
                          <b-button variant="link" block @click="() => $refs.sortByDropdown.hide(true)">Close</b-button>
                        </div>
                        
                      </b-dropdown>
                    </div>

                    <div class="btn-group mr-2 mb-2 mb-sm-0">
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-6">

                  </div>
                  <div class="col-md-6">
                    <div class="text-right">
                      <b-form-checkbox v-model="criteria.normalisedPerformance" switch class="mr-1" style="display:inline-block;">
                      </b-form-checkbox>{{$t("pages.indexView.normalisedPerformance")}}
                    </div>
                  </div>
                </div>
                <div class="row" v-if="viewMode === 'grid'">
                  <div class="col-lg-6">
                    <ul class="list-inline my-3 ecommerce-sortby-list" >
                      <li class="list-inline-item">
                        Legend: 
                      </li>
                      <li class="list-inline-item">
                        <div class="badge badge-warning" >Provider</div>
                      </li>
                    <li class="list-inline-item">
                        <div class="badge badge-primary" >Asset class</div>
                      </li>
                      <li class="list-inline-item">
                        <div class="badge badge-success">Instrument type</div>
                      </li>
                      <li class="list-inline-item">
                        <div class="badge badge-secondary"  >Rebalance frequency</div>
                      </li>
                    </ul>
                  </div>
                  <div class="col-lg-6 text-right">
                    <b-form-checkbox 
                      v-model="mainChartYAxisLogarithmicType" switch class="mr-1" 
                      style="display:inline-block;">
          
                    </b-form-checkbox>{{$t("pages.indexView.yAxisLogarithmic")}}
                  </div>
                </div>
              
                <div class="row no-gutters" v-if="viewMode === 'grid'">
                  <div class="col-lg-4" v-for="item in items" :key="item.id">
                    <div class="product-box" style="padding-bottom:0">
                    
                      <highcharts v-if="item.chartOptions"
                        :constructorType="'stockChart'"
                        :options="item.chartOptions">
                      </highcharts>
                      <ul class="list-inline my-3 ecommerce-sortby-list" style="position: absolute;top: -10px;left: 5px; right:40px;">
                        <li class="list-inline-item">
                          <div class="badge badge-warning" >{{(item.provider || {}).displayName}}</div>
                        </li>
                        <li class="list-inline-item">
                          <div class="badge badge-primary" >{{assetClassMap[item.assetClass || {}]}}</div>
                        </li>
                        <li class="list-inline-item">
                          <div class="badge badge-success">{{instrumentTypeMap[item.instrumentType || {}]}}</div>
                        </li>
                        <li class="list-inline-item">
                          <div class="badge badge-secondary">{{rebalanceFrequencyMap[item.rebalanceFrequency || {}]}}</div>
                        </li>
                      </ul>
                      <div class="text-center">
                        <p class="font-size-12 mb-1" :title="'Index Type: ' + (item.indexType || {}).displayName">Index type: {{(item.indexType || {}).displayName}}</p>
                        <h5 class="font-size-15">
                          <a :href="'indices/' + item.id + '/view'" class="text-dark">{{item.displayName}}</a>
                        </h5>
                        <p class="font-size-12 mt-3 mb-0 text-info" :title="'Market: ' + (item.market || {}).displayName">Market: {{(item.market || {}).displayName}}</p>
                      </div>
                      
                      <table class="mt-4 table table-sm table-hover table-borderless small">
                        <tr v-if="statColumns.ytd.visible || statColumns.mtd.visible">
                          <td>YTD</td>
                          <td class="text-primary"> {{item.performance.ytdFormatted}} <span v-if="item.performance.ytdFormatted">%</span></td>
                          <td>MTD</td>
                          <td class="text-primary"> {{item.performance.mtdFormatted}} <span  v-if="item.performance.mtdFormatted">%</span></td>
                        </tr>
                        <tr v-if="statColumns.annualisedReturn.visible || statColumns.realisedVolatility.visible">
                          <td class="no-wrap">{{$t('tables.indices.columns.annualisedReturn')}}</td>
                          <td class="text-primary font-weight-bold">
                            <span v-if="item.statistic">
                              {{item.statistic.values.annualisedReturnFormatted}} <span  v-if="item.statistic.values.annualisedReturnFormatted">%</span>
                            </span>
                          </td>
                          <td class="no-wrap">{{$t('tables.indices.columns.realisedVolatility')}}</td>
                          <td class="text-primary font-weight-bold">
                            <span v-if="item.statistic">
                              {{item.statistic.values.realisedVolatilityFormatted}} <span  v-if="item.statistic.values.realisedVolatilityFormatted">%</span>
                            </span>
                          </td>
                        </tr>
                        <tr v-if="statColumns.downsideVolatility.visible || statColumns.maxDrawdown.visible">
                          <td class="no-wrap">{{$t('tables.indices.columns.downsideVolatility')}}</td>
                          <td class="text-primary font-weight-bold">
                            <span v-if="item.statistic">
                              {{item.statistic.values.downsideVolatilityFormatted}} <span  v-if="item.statistic.values.downsideVolatilityFormatted">%</span>
                          </span>
                          </td>
                          <td class="no-wrap">
                            {{$t('tables.indices.columns.maxDrawdown')}}
                            </td>
                          <td class="text-primary font-weight-bold">
                            <span v-if="item.statistic">
                              {{item.statistic.values.maxDrawdownFormatted}} <span  v-if="item.statistic.values.maxDrawdownFormatted">%</span>
                            </span>
                          </td>
                        </tr>
                        <tr v-if="statColumns.winningMonths.visible || statColumns.sharpeRatio.visible">
                          <td class="no-wrap">{{$t('tables.indices.columns.winningMonths')}}</td>
                          <td class="text-primary font-weight-bold">
                            <span v-if="item.statistic">
                              {{item.statistic.values.winningMonthsFormatted}} <span  v-if="item.statistic.values.winningMonthsFormatted">%</span>
                            </span>
                          </td>
                          <td class="no-wrap">
                            {{$t('tables.indices.columns.sharpeRatio')}}
                            </td>
                          <td class="text-primary font-weight-bold">
                            <span v-if="item.statistic">
                              {{item.statistic.values.sharpeRatioFormatted}} 
                          </span>
                          </td>
                        </tr>
                        <tr v-if="statColumns.sortinoRatio.visible || statColumns.calmarRatio.visible">
                          <td class="no-wrap">{{$t('tables.indices.columns.sortinoRatio')}}</td>
                          <td class="text-primary font-weight-bold">
                            <span v-if="item.statistic">
                              {{item.statistic.values.sortinoRatioFormatted}} 
                          </span>
                          </td>
                          <td class="no-wrap">
                            {{$t('tables.indices.columns.calmarRatio')}}
                            </td>
                          <td class="text-primary font-weight-bold">
                            <span v-if="item.statistic">
                              {{item.statistic.values.calmarRatioFormatted}} 
                          </span>
                          </td>
                        </tr>
                      </table>
                    
                      <div class=" text-center" style="position: absolute;top: 30%;left:0; right:0; " v-if="composeIndexEnabled && !composeIndexItems.find(a => a.id === item.id)">
                        <button style="opacity:0.8" class="btn  btn-lg btn-primary"  @click="selectComposeIndexPart(item)">Select</button>
                      </div>

                      <div  class=" text-center" style="position: absolute;top: 30%;left:0; right:0; " v-if="(screening.enabled && !screening.items.find(a => a.id === item.id)) && isIndexAllowedForScreening(item)">
                        <button style="opacity:0.8"  class="btn  btn-lg btn-secondary"  @click="addIndexToScreening(item)">Select</button>
                      </div>
                    
                      <div style="position: absolute;top: 0;right: 0;" v-if="!composeIndexEnabled && !screening.enabled">
                      
                        <b-dropdown left variant="white" v-if="isAdmin || item.allowEdit || item.allowDelete">
                          <template v-slot:button-content>
                            <i class="mdi mdi-dots-vertical m-0 text-muted font-size-20"></i>
                          </template>
                          <b-dropdown-item v-if="isAdmin"  @click="uploadFile(item)">{{$t("tables.indices.uploadFile")}}</b-dropdown-item>
                          <b-dropdown-item v-if="item.allowEdit"  :href="'indices/' + item.id + '/edit'">{{$t("tables.indices.edit")}}</b-dropdown-item>
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item v-if="item.allowDelete" @click="confirmDelete(item)">{{$t("common.delete")}}</b-dropdown-item>
                        </b-dropdown>
                      </div>

                      
                    </div>
                  </div>
                </div>

                <div  v-if="viewMode === 'list'">
                  <simplebar style="max-height: 700px">
                    <table class="table table-centered mb-0 table-nowrap table-sm">
                      <thead  style="position: sticky; top: 0;">
                        <tr>
                          <th class="text-center" v-if="composeIndexEnabled || screening.enabled">
                            <button class="btn  btn-sm btn-secondary" v-if="composeIndexEnabled" @click="selectAllComposeIndexPart">
                              Select All
                            </button>
                            <button class="btn  btn-sm btn-secondary" 
                              v-if="screening.enabled" 
                              @click="addAllIndexToScreening"
                            >
                              Select All
                            </button>
                          </th>
                          <th class="text-center" style="width: 50px;" v-if="!composeIndexEnabled && !screening.enabled">
                          
                          </th>
                          <th style="width: 30px;">
                            <a href="javascript:void(0)" v-if="!showOnlyBookmarks"  >
                              <i style="font-size:1.7rem;color:white" class="mdi mdi-star-outline" @click="() => showOnlyBookmarks = !showOnlyBookmarks"/>
                            </a>
                            <a href="javascript:void(0)" v-if="showOnlyBookmarks" >
                              <i style="font-size:1.7rem" class="mdi mdi-star text-warning" @click="() => showOnlyBookmarks = !showOnlyBookmarks"/>
                            </a>
                          
                          </th>
                          <th style="cursor:pointer; min-width:250px;width:300px;" @click="sortBy('name')">
                            <span class="mr-1"> {{$t('tables.indices.columns.name')}}</span>
                          
                            <span class="float-right" v-if="criteria.sortBy === 'name'">
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;min-width: 100px;" @click="sortBy('index-type')" v-if="statColumns.indexType.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.indexType')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'index-type'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;min-width: 120px;" @click="sortBy('index-sub-type')" v-if="statColumns.indexSubType.visible">
                            <span class="mr-1">Index Sub Type</span>
                             <span class="float-right" v-if="criteria.sortBy === 'index-sub-type'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;min-width: 80px;" @click="sortBy('market')" v-if="statColumns.market.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.market')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'market'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;min-width: 100px;" @click="sortBy('provider')" v-if="statColumns.provider.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.provider')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'provider'">
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;min-width: 100px;" @click="sortBy('asset-class')" v-if="statColumns.assetClass.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.assetClass')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'asset-class'">
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;min-width: 100px;white-space: normal;" @click="sortBy('instrument-type')" v-if="statColumns.instrumentType.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.instrumentType')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'instrument-type'">
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;min-width: 100px;white-space: normal;" @click="sortBy('rebalance-frequency')" v-if="statColumns.rebalanceFrequency.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.rebalanceFrequency')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'rebalance-frequency'">
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;" @click="sortBy('ytd')" v-if="statColumns.ytd.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.ytd')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'ytd'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;" @click="sortBy('mtd')" v-if="statColumns.mtd.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.mtd')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'mtd'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;max-width:90px;white-space: normal;" @click="sortBy('annualised-return')" v-if="statColumns.annualisedReturn.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.annualisedReturn')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'annualised-return'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;max-width:100px;min-width:100px;white-space: normal;" @click="sortBy('realised-volatility')" v-if="statColumns.realisedVolatility.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.realisedVolatility')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'realised-volatility'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th  style="cursor:pointer;max-width:110px;min-width:110px;white-space: normal;;"  @click="sortBy('downside-volatility')" v-if="statColumns.downsideVolatility.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.downsideVolatility')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'downside-volatility'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;max-width:110px;min-width:110px;white-space: normal;" @click="sortBy('max-drawdown')" v-if="statColumns.maxDrawdown.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.maxDrawdown')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'max-drawdown'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;max-width:110px;min-width:110px;white-space: normal;" @click="sortBy('winning-months')" v-if="statColumns.winningMonths.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.winningMonths')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'winning-months'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;max-width:90px;min-width:90px;white-space: normal;" @click="sortBy('sharpe-ratio')" v-if="statColumns.sharpeRatio.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.sharpeRatio')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'sharpe-ratio'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;max-width:90px;min-width:90px;white-space: normal;" @click="sortBy('sortino-ratio')" v-if="statColumns.sortinoRatio.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.sortinoRatio')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'sortino-ratio'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                          <th style="cursor:pointer;max-width:90px;min-width:90px;white-space: normal;" @click="sortBy('calmar-ratio')" v-if="statColumns.calmarRatio.visible">
                            <span class="mr-1">{{$t('tables.indices.columns.calmarRatio')}}</span>
                            <span class="float-right" v-if="criteria.sortBy === 'calmar-ratio'" >
                              <span v-if="criteria.sortByDir === 'asc'">
                                <i class="fa  fas fa-sort-up"></i>
                              </span>
                              <span v-if="criteria.sortByDir === 'desc'">
                                <i class="fa  fas fa-sort-down"></i>
                              </span>
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in filteredItems" :key="item.id">
                          <td class="text-center" v-if="composeIndexEnabled || screening.enabled">
                            <button class="btn  btn-sm btn-secondary" v-if="composeIndexEnabled && !composeIndexItems.find(a => a.id === item.id)" @click="selectComposeIndexPart(item)">
                              Select
                            </button>
                            <button class="btn  btn-sm btn-secondary" 
                              v-if="(screening.enabled && !screening.items.find(a => a.id === item.id)) && isIndexAllowedForScreening(item)" 
                              @click="addIndexToScreening(item)"
                            >
                              Select
                            </button>
                          </td>
                        
                          <td style="width: 50px;" class="text-center" v-if="!composeIndexEnabled && !screening.enabled">
                            <b-dropdown left variant="white" size="sm" v-if="isAdmin || item.allowEdit || item.allowDelete">
                              <template v-slot:button-content>
                                <i class="mdi mdi-dots-vertical m-0 text-muted font-size-20"></i>
                              </template>
                              <b-dropdown-item v-if="isAdmin" @click="uploadFile(item)">{{$t("tables.indices.uploadFile")}}</b-dropdown-item>
                              <b-dropdown-item v-if="item.allowEdit" :href="'indices/' + item.id + '/edit'">{{$t("tables.indices.edit")}}</b-dropdown-item>
                              <b-dropdown-divider></b-dropdown-divider>
                              <b-dropdown-item v-if="item.allowDelete" @click="confirmDelete(item)">{{$t("common.delete")}}</b-dropdown-item>
                            </b-dropdown>
                          </td>
                          <td style="width: 30px;">
                            <a href="javascript:void(0)" v-if="!bookmarks[item.id]" @click="addBookmark(item.id)" ><i style="font-size:1.7rem" class="mdi mdi-star-outline text-muted "/></a>
                            <a href="javascript:void(0)" v-if="bookmarks[item.id]" @click="removeBookmark(item.id)"><i style="font-size:1.7rem" class="mdi mdi-star text-warning"/></a>
                          </td>
                          <td style="min-width:250px;width:300px;" >
                            <strong style="white-space: initial;">
                              <a :href="'indices/' + item.id + '/view'" class="text-dark">{{item.displayName}}</a>
                            </strong>
                            <div v-if="item.optimization && item.optimization.solutionTitle">
                              <small class="text-muted">
                                Solution: {{item.optimization.solutionTitle}}
                              </small>
                            </div>
                          </td>
                          <td v-if="statColumns.indexType.visible">{{(item.indexType || {}).displayName}}</td>
                          <td v-if="statColumns.indexSubType.visible">{{(item.indexSubType || {}).displayName}}</td>
                          <td v-if="statColumns.market.visible">{{(item.market || {}).displayName}}</td>
                          <td v-if="statColumns.provider.visible">{{(item.provider || {}).displayName}}</td>
                          <td v-if="statColumns.assetClass.visible">{{assetClassMap[item.assetClass || {}]}}</td>
                          <td v-if="statColumns.instrumentType.visible">{{instrumentTypeMap[item.instrumentType || {}]}}</td>
                          <td v-if="statColumns.rebalanceFrequency.visible">{{rebalanceFrequencyMap[item.rebalanceFrequency || {}]}}</td>
                        
                          <td v-if="statColumns.ytd.visible">
                            {{item.performance.ytdFormatted}} <span v-if="item.performance.ytdFormatted">%</span>
                          </td>
                          <td v-if="statColumns.mtd.visible">
                            {{item.performance.mtdFormatted}} <span  v-if="item.performance.mtdFormatted">%</span>
                          </td>
                          <td v-if="statColumns.annualisedReturn.visible">
                          <span v-if="item.statistic">
                              {{item.statistic.values.annualisedReturnFormatted}} <span  v-if="item.statistic.values.annualisedReturnFormatted">%</span>
                          </span>
                          </td>
                          <td v-if="statColumns.realisedVolatility.visible">
                          <span v-if="item.statistic">
                              {{item.statistic.values.realisedVolatilityFormatted}} <span  v-if="item.statistic.values.realisedVolatilityFormatted">%</span>
                          </span>
                          </td>
                          <td v-if="statColumns.downsideVolatility.visible">
                          <span v-if="item.statistic">
                              {{item.statistic.values.downsideVolatilityFormatted}} <span  v-if="item.statistic.values.downsideVolatilityFormatted">%</span>
                          </span>
                          </td>
                        
                          <td v-if="statColumns.maxDrawdown.visible">
                          <span v-if="item.statistic">
                              {{item.statistic.values.maxDrawdownFormatted}} <span  v-if="item.statistic.values.maxDrawdownFormatted">%</span>
                          </span>
                          </td>
                          <td v-if="statColumns.winningMonths.visible">
                          <span v-if="item.statistic">
                              {{item.statistic.values.winningMonthsFormatted}} <span  v-if="item.statistic.values.winningMonthsFormatted">%</span>
                          </span>
                          </td>
                          <td v-if="statColumns.sharpeRatio.visible">
                          <span v-if="item.statistic">
                              {{item.statistic.values.sharpeRatioFormatted}} 
                          </span>
                          </td>
                          <td v-if="statColumns.sortinoRatio.visible">
                          <span v-if="item.statistic">
                              {{item.statistic.values.sortinoRatioFormatted}} 
                          </span>
                          </td>
                          <td v-if="statColumns.calmarRatio.visible">
                          <span v-if="item.statistic">
                              {{item.statistic.values.calmarRatioFormatted}} 
                          </span>
                          </td>
                        </tr>
                        
                      </tbody>
                    </table>
                  </simplebar>
                </div>

                <div class="row mt-4">
                  <div class="col">
                    <div class="row justify-content-md-between align-items-md-center">
                      <div class="col-xl-3">{{$t('common.totalRecords')}} {{totalItems}}</div>
                      <div class="col-xl-4">
                        <select class="form-control input-sm" v-model="perPage">
                          <option :value="20">20 records per page</option>
                          <option :value="50">50 records per page</option>
                          <option :value="100">100 records per page</option>  
                        </select>
                      </div>
                      <div class="col-xl-5">
                        <div class="text-md-right float-xl-right mt-2 pagination-rounded">
                        
                          <b-pagination v-if="!initializing"
                            v-model="currentPage"
                            :total-rows="totalItems"
                            :per-page="perPage"
                          
                          ></b-pagination>
                        </div>
                      </div>
                  

                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            <div v-if="isBusy" style="position:absolute;left:0; top:0; bottom:0; right:0;background:white;opacity:0.5;"></div>
          </div>
        </div>
      </div>
    </div>
     <!-- Modal -->
    <b-modal
      id="modal-1"
      v-model="uploadVisible"
      :title="$t('pages.indices.uploadFile')"
      title-class="text-dark font-18"
      hide-footer
    >
      
         <div class="form-group">
          
          <input type="file" :ref="'file'" class="form-control" v-on:change="handleFileUpload()"/>
        </div>
        
        <div>
          <b-button class="ml-1 float-left" @click="hideUploadFile">Close</b-button>
          <!-- <button type="submit" class="btn btn-success float-right" :disabled="userSaving">
            <i class="fa fa-spin fa-spinner" v-if="userSaving"></i>
            Сохранить
          </button> -->
        </div>
     
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>